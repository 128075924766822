@font-face {
    font-family: "Cocogoose";
    src: url("./assets/fonts/Cocogoose-Pro.ttf");
}

@font-face {
    font-family: "Nunito";
    src: url("./assets/fonts/NunitoSans-Regular.ttf");
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Form */
.MuiInputBase-input {
    font-family: "Nunito", sans-serif !important;
    font-size: 1rem !important;
}

.MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.MuiSelect-select:focus {
    border-radius: 25px !important;
    background-color: #fff !important;
}

.MuiMenuItem-root {
    font-family: "Nunito", sans-serif !important;
}

.MuiMenu-paper {
    max-height: 500px !important;
}

/* Scrollbar */

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Dialog */

.MuiDialogTitle-root,
.MuiDialogContent-root {
    padding: 1rem 0px !important;
}

.MuiDialogTitle-root > h2 {
    font-size: 1.4rem;
    font-family: "Cocogoose", "Helvetica", "Arial", sans-serif !important;
    text-align: center;
    font-weight: bold;
    color: #0080ff;
}

.MuiDialogContent-root > p {
    font-size: 1.25rem;
    font-family: "Nunito", "Helvetica", "Arial", sans-serif !important;
    text-align: center;
}

.MuiDialogContent-root > p > button {
    margin-top: 2rem;
}

.MuiOutlinedInput-inputMarginDense {
    padding-top: 8px !important;
}

/* Table */

.MuiTable-root {
    margin-bottom: 2rem;
}

.MuiTableCell-head,
.MuiTableCell-body {
    color: #0080ff !important;
    font-family: "Nunito", sans-serif !important;
}
